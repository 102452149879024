<template>
  <div>
    <div class="mass-action-settings">
      <cards-stage-select
        v-model="actionData.stage_type"
        size="small"
      ></cards-stage-select>

      <cards-stage-negotiation-select
        v-model="actionData.negotiation_type"
        size="small"
        style="margin-left: 20px"
      ></cards-stage-negotiation-select>

      <el-select
        v-model="actionData.order"
        placeholder="Номер по порядку"
        size="small"
        style="margin-left: 20px"
      >
        <el-option
          v-for="order in 5"
          :key="order"
          :label="order"
          :value="order"
        >
        </el-option>
      </el-select>

      <el-popconfirm
        style="margin-left: 20px"
        confirm-button-text='Да'
        cancel-button-text='нет'
        icon="el-icon-question"
        title="Вы уверены, что хотите удалить шаг?"
        @confirm="doAction"
      >
        <el-button
          slot="reference"
          size="small"
          type="primary"
          :disabled="actionButtonDisabled"
        >
          Запустить
        </el-button>

      </el-popconfirm>

    </div>

    <mass-action-result
      v-if="result"
      :result="result"
      :error-props="['id', 'info.last_name', 'info.first_name', 'info.middle_name', 'info.company']"
    ></mass-action-result>
  </div>
</template>

<script>
import {ActionTemplateMixin} from "@/components/massActions/actionTemplates/ActionTemplateMixin";
import CardsStageSelect from "@/components/filters/cards/CardsStageSelect.vue";
import MassActionResult from "@/components/massActions/MassActionResult.vue";
import CardsStageNegotiationSelect from "@/components/filters/cards/CardsStageNegotiationSelect.vue";

export default {
  name: "card-delete-negotiation-action",
  mixins: [ActionTemplateMixin],
  components: {CardsStageNegotiationSelect, MassActionResult, CardsStageSelect},

  computed: {
    actionButtonDisabled() {
      return !this.actionData.stage_type
        || !this.actionData.negotiation_type;
    }
  },
  data() {
    return {
      actionData: {
        stage_type: null,
        negotiation_type: null,
        order: null,
      }
    }
  },
  methods: {},
}
</script>
